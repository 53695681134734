import { render, staticRenderFns } from "./nTour.vue?vue&type=template&id=1d147786&scoped=true"
import script from "./nTour.vue?vue&type=script&lang=js"
export * from "./nTour.vue?vue&type=script&lang=js"
import style0 from "./nTour.vue?vue&type=style&index=0&id=1d147786&prod&scoped=true&lang=scss"
import style1 from "./nTour.vue?vue&type=style&index=1&id=1d147786&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d147786",
  null
  
)

export default component.exports